import testimonials from './_testimonials'
import features from './_features'
import products from './_products'
import laptop from './_laptop'
import webinars from './_webinars'

export default () => {
  testimonials()
  features()
  products()
  laptop()
  webinars()
}
